import * as React from "react";
import "../i18n";
import Seo from "../components/shared/Seo";

import "./style.css";
import { useTranslation } from "react-i18next";
import Layout from "../components/Layout/Layout";
import BlogContent from "../templates/BlogContent";

export default function TermsAndConditionsPage() {
  const { t } = useTranslation();
  return (
    <Layout>
      <Seo title="Terms and conditions" description="Read our terms and conditions in full via our website." canonicalPath="/terms-and-conditions"/>
      <BlogContent blogpost={t("terms_conditions_page.content")} />
    </Layout>
  );
}
